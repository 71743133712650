<template>
  <div v-loading="loading" class="recharge">
    <div style="display: flex; padding: 0px 0">
      <!-- <el-select
        v-model="value"
        style="margin-left: 0px"
        placeholder="请选择课程名称"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #01b37c;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          cursor: pointer;
        "
      >
        搜索
      </div>
      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #fff;
          color: #01b37c;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          border: 1px solid #01b37c;
          box-sizing: border-box;
          cursor: pointer;
        "
      >
        重置
      </div> -->
      <div
        style="
          width: 110px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #01b37c;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 0px;
          cursor: pointer;
        "
        @click="openAddClassFn"
      >
        新建门店
      </div>
    </div>

    <div style="display: flex; flex-wrap: wrap; width: 100%; margin-top: 20px">
      <div
        v-for="(item, index) in AllDoorData"
        :key="index"
        style="
          width: 526px;
          min-height: 321px;
          padding: 20px;
          box-sizing: border-box;
          background-color: #f7f9fa;
          margin-bottom: 20px;
          margin-right: 20px;
        "
      >
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 32px;
          "
        >
          <div style="height: 32px; width: 32px; background-color: #fff">
            <!-- 选中 -->
            <img
              v-if="item.IsOpen == true"
              src="http://image.mengqiyun.com/%E9%97%A8%E5%BA%97%E6%9D%83%E9%99%90.png"
              style="width: 32px; height: 32px"
              alt=""
            />
            <!-- 未选 -->
            <img
              v-if="item.IsOpen == false"
              src="http://image.mengqiyun.com/%E9%97%A8%E5%BA%97%E6%9D%83%E9%99%90.png"
              style="width: 32px; height: 32px"
              alt=""
            />
          </div>
          <el-switch
            v-model="item.IsOpen"
            active-text=""
            inactive-text=""
            active-color="#01B37C"
            @change="openDoorFn(item,index)"
          >
          </el-switch>
        </div>

        <div>
          <div
            style="
              height: 30px;
              line-height: 30px;
              margin-top: 20px;
              color: #333;
              font-size: 20px;
              font-weight: bold;
            "
          >
            {{ item.Name }}
          </div>

          <div
            style="
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              margin-top: 5px;
            "
          >
            <div style="max-width: 230px; color: #666; font-size: 16px">
              {{ item.Address }}
            </div>
            <div style="color: #666; font-size: 16px">{{ item.Phone }}</div>
          </div>

          <div
            style="
              border-bottom: 1px solid #efefef;
              width: 100%;
              margin-top: 16px;
            "
          ></div>

          <div style="padding-top: 10px">
            <div
              v-for="(val, index2) in item.Rooms"
              :key="index2"
              style="
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="color: #333; font-size: 16px">
                {{ val.Name }}
              </div>
              <div
                @click="openEditRoomsFn(item, index, val, index2)"
                style="font-size: 16px; color: #01b37c; cursor: pointer"
              >
                编辑
              </div>
            </div>
          </div>

          <div
            style="display: flex; justify-content: flex-end; margin-top: 20px"
          >
            <div
              style="
                width: 120px;
                height: 40px;
                border-radius: 5px;
                line-height: 40px;
                text-align: center;
                background-color: #fff;
                border: 1px solid #e6e6e6;
                box-sizing: border-box;
                color: #333;
                cursor: pointer;
              "
              @click="openDoorSetUpFn(item)"
            >
              门店设置
            </div>

            <div
              style="
                width: 120px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                background-color: #fff;
                color: #eb5b5b;
                border-radius: 5px;
                font-size: 16px;
                margin-left: 20px;
                border: 1px solid #eb5b5b;
                box-sizing: border-box;
                cursor: pointer;
              "
              @click="DeleteStoreFn(item)"
            >
              删除门店
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新建门店 -->
    <el-dialog title="新建门店" :visible.sync="dialogVisible1" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              门店名称
            </div>
            <el-input
              v-model="form.Name"
              style="flex: 1"
              placeholder="请输入门店名称"
            />
          </div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              联系电话
            </div>
            <el-input
              v-model="form.Phone"
              style="flex: 1"
              placeholder="请输入联系电话"
            />
          </div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              门店地址
            </div>
            <el-input
              v-model="form.Address"
              style="flex: 1"
              placeholder="请输入门店地址"
            />
          </div>

          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              门店教室
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <div
                style="
                  position: relative;
                  width: 120px;
                  height: 40px;
                  position: relative;
                  margin-right: 10px;
                  margin-bottom: 10px;
                "
                v-for="(item, index) in form.Rooms"
                :key="index"
              >
                <img
                  @click="delRoomFn(index)"
                  style="
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    right: 0px;
                    z-index: 999;
                    cursor: pointer;
                  "
                  src="http://image.mengqiyun.com/shanchu6666.png"
                  alt=""
                />
                <el-input
                  v-model="item.Name"
                  style="width: 120px; height: 40px; padding: 0 0px"
                  placeholder="教室名称"
                />
              </div>
              <div
                @click="AddRoomsFn"
                style="
                  width: 120px;
                  height: 40px;
                  text-align: center;
                  line-height: 40px;
                  border: 1px solid #01b37c;
                  color: #01b37c;
                  background-color: #fff;
                  font-size: 16px;
                  border-radius: 5px;
                  cursor: pointer;
                  box-sizing: border-box;
                "
              >
                添加教室
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offAddClassFn"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="AddStoreFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 门店设置 -->
    <el-dialog title="门店设置" :visible.sync="dialogVisible2" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              门店名称
            </div>
            <el-input
              v-model="form.Name"
              style="flex: 1"
              placeholder="请输入门店名称"
            />
          </div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              联系电话
            </div>
            <el-input
              v-model="form.Phone"
              style="flex: 1"
              placeholder="请输入联系电话"
            />
          </div>
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              门店地址
            </div>
            <el-input
              v-model="form.Address"
              style="flex: 1"
              placeholder="请输入门店地址"
            />
          </div>

          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              门店教室
            </div>
            <div style="flex: 1; display: flex; flex-wrap: wrap">
              <div
                style="
                  position: relative;
                  width: 120px;
                  height: 40px;
                  position: relative;
                  margin-right: 10px;
                  margin-bottom: 10px;
                "
                v-for="(item, index) in form.Rooms"
                :key="index"
              >
                <img
                  @click="delRoomFn(index)"
                  style="
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    right: 0px;
                    z-index: 999;
                    cursor: pointer;
                  "
                  src="http://image.mengqiyun.com/shanchu6666.png"
                  alt=""
                />
                <el-input
                  v-model="item.Name"
                  style="width: 120px; height: 40px; padding: 0 0px"
                  placeholder="教室名称"
                />
              </div>
              <div
                @click="AddRoomsFn"
                style="
                  width: 120px;
                  height: 40px;
                  text-align: center;
                  line-height: 40px;
                  border: 1px solid #01b37c;
                  color: #01b37c;
                  background-color: #fff;
                  font-size: 16px;
                  border-radius: 5px;
                  cursor: pointer;
                  box-sizing: border-box;
                "
              >
                添加教室
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offDoorSetUpFn"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="UpdateStoreFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 课室编辑 -->
    <el-dialog title="课室编辑" :visible.sync="dialogVisible3" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              课室名称
            </div>
            <el-input
              v-model="form2.EditRoomName"
              style="flex: 1"
              placeholder="请输入门店名称"
            />
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offEditRoomsFn"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="UpdateStoreFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetAllStore,
  AddStore,
  UpdateStore,
  DeleteStore,
} from "@/request/api.js";

export default {
  data() {
    return {
      tableData: [],

      tableData2: [],

      ChoosePay: "",

      store_id: "",

      src: "",

      StoreC: "",

      url: "",

      Authorization: {},

      keyUrl: "",

      //教案
      loading: true,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      switch1: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      AllDoorData: [], //所有门店
      form: {
        Id: 0,
        Name: "",
        Address: "",
        Phone: "",
        IsOpen: true,
        Rooms: [{ Id: 0, Name: "" }],
      },
      form2: {
        EditRoomName: "",
      },
      EditRoomCheck: false,
      EditRoomIndex: -1,
    };
  },

  methods: {
    //打开新建门店
    openAddClassFn() {
      this.dialogVisible1 = true;
      this.EditRoomCheck = false;
    },
    offAddClassFn() {
      this.dialogVisible1 = false;
    },

    //打开修改课室
    openEditRoomsFn(item, index, val, index2) {
      this.dialogVisible3 = true;
      this.EditRoomCheck = true;
      this.form = item;
      this.form2.EditRoomName = val.Name;
      this.EditRoomIndex = index2;
    },
    offEditRoomsFn() {
      this.dialogVisible3 = false;
      this.EditRoomCheck = false;
      this.GetAllStoreFn();
    },

    //打开门店设置
    openDoorSetUpFn(e) {
      this.form = e;
      console.log("this.form==>", this.form);
      this.dialogVisible2 = true;
      this.EditRoomCheck = false;
    },
    offDoorSetUpFn() {
      this.dialogVisible2 = false;
    },

    //添加教室
    AddRoomsFn() {
      this.form.Rooms.push({ Id: 0, Name: "" });
    },

    //删除教师
    delRoomFn(e) {
      this.form.Rooms.splice(e, 1);
    },

    // 提交新建门店
    AddStoreFn() {
      let that = this;
      console.log("this.form==>", this.form);
      if(that.form.Name == ''){
        that.$message.error("门店名称不能为空");
        return
      }
      if(that.form.Address == ''){
        that.$message.error("门店地址不能为空");
        return
      }
      if(that.form.Phone == ''){
        that.$message.error("门店电话不能为空");
        return
      }


      AddStore(that.form).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("提交新建门店==>", res.Data);
          that.dialogVisible1 = false;

          that.$message({
            message: "添加成功",
            type: "success",
          });

          (that.form = {
            Id: 0,
            Name: "",
            Address: "",
            Phone: "",
            IsOpen: true,
            Rooms: [{ Id: 0, Name: "" }],
          }),
            that.GetAllStoreFn();
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 提交修改门店
    UpdateStoreFn() {
      let that = this;
      if(that.form.Name == ''){
        that.$message.error("门店名称不能为空");
        return
      }
      if(that.form.Address == ''){
        that.$message.error("门店地址不能为空");
        return
      }
      if(that.form.Phone == ''){
        that.$message.error("门店电话不能为空");
        return
      }

      if (that.EditRoomCheck == true) {
        console.log("修改课室");
        this.form.Rooms[that.EditRoomIndex].Name = this.form2.EditRoomName;
      }
      console.log("this.form==>", this.form);
      UpdateStore(that.form).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("提交修改门店==>", res.Data);
          that.dialogVisible2 = false;
          that.dialogVisible3 = false;
          that.EditRoomCheck = false;

          that.$message({
            message: "修改成功",
            type: "success",
          });

          (that.form = {
            Id: 0,
            Name: "",
            Address: "",
            Phone: "",
            IsOpen: true,
            Rooms: [{ Id: 0, Name: "" }],
          }),
            that.GetAllStoreFn();
        } else {
          that.$message.error(res.Message);
        }
      });
    },

    //门店状态
    openDoorFn(row) {
      let that = this;
      let item = row;
      console.log("row==>", row.IsOpen);
      // item.IsOpen = !item.IsOpen;
      this.form = item;
      console.log("this.form==>", this.form);
      that.UpdateStoreFn();
    },

    // 删除门店
    DeleteStoreFn(row) {
      let that = this;
      that
        .$confirm("确定删除该门店信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          DeleteStore({
            Id: row.Id,
          }).then((res) => {
            console.log(" 管理员删除门店==>", res);
            if (res.Code === 2000) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.GetAllStoreFn();
            } else {
              that.$message({
                type: "error",
                message: res.Message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 获取所有门店
    GetAllStoreFn() {
      let that = this;
      GetAllStore().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          that.loading = false;
          console.log("获取所有门店==>", res.Data);
          that.AllDoorData = res.Data;
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    copy() {
      this.$message({
        message: "复制成功",
        type: "success",
      });
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", this.keyUrl);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.CopySuccessMessage();
    },
    ClickTimeFn() {
      this.StoreC = "天数";
      this.tableData = [];
      this.tableData2.forEach((item, index) => {
        if (item.type === 1) {
          this.tableData.push(item);
        }
      });
    },
    ClickNumFn() {
      this.tableData = [];
      this.StoreC = "次数";
      this.tableData2.forEach((item, index) => {
        if (item.type === 0) {
          this.tableData.push(item);
        }
      });
    },

    //购买商品
    shoppingFn(scope) {
      GetNetworkLinks().then((res) => {
        // createElement() 方法通过指定名称创建一个元素
        var copyInput = document.createElement("input");
        //val是要复制的内容
        copyInput.setAttribute("value", res.Data);
        document.body.appendChild(copyInput);
        copyInput.select();
        try {
          var copyed = document.execCommand("copy");
          if (copyed) {
            document.body.removeChild(copyInput);
            this.$message({
              message: "复制成功",
              type: "success",
            });
          }
        } catch {
          this.$message({
            message: "复制失败",
            type: "error",
          });
        }

        window.open(res.Data);
      });
    },
    //取消
    buyWait() {
      this.dialogVisible1 = false;
      this.ChoosePay = "";
    },
    authorizationSure() {
      useCardSecret({ CardId: this.Authorization.cardId, type: 1 }).then(
        (res) => {
          if (res.Code === 2000) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "请不要使用代理时长授权码来充值次数包",
              type: "error",
            });
          }
        }
      );
    },
  },

  created() {
    this.GetAllStoreFn(); //获取所有门店
  },
};
</script>

<style lang="less" scoped>
.recharge {
  width: 100%;
  background-color: #fff;
}
</style>